<template>
  <!-- Loading -->
  <div
    v-if="loading"
    class="d-flex justify-center"
  >
    <v-progress-circular
      :size="50"
      indeterminate
      color="primary"
    >
    </v-progress-circular>
  </div>
  <div v-else>
    <div>
      <v-app-bar
        color="transparent"
        elevation="0"
      >
        <div class="mt-3">
          <AppBarI18n></AppBarI18n>
        </div>

        <v-spacer></v-spacer>

        <a
          href="https://www.anita-lee.com"
          target="_blank"
        >
          <div
            dir="ltr"
            class="d-flex"
          >
            <v-img
              :src="appLogo"
              alt="logo"
              contain
              eager
              max-height="35px"
              max-width="35px"
            ></v-img>

            <v-img
              :src="appName"
              alt="name"
              contain
              eager
              max-height="40px"
              max-width="120px"
              class="mt-2"
            ></v-img>
          </div>
        </a>
      </v-app-bar>
    </div>
    <div
      class="align-center justify-center d-flex"
      style="height: 90vh"
    >
      <v-card
        class="pa-3 pt-0 mt-0"
        max-width="448"
      >
        <v-card-text class="pt-5 pb-1">
          <h5 class="text-h5 mb-3">
            <v-icon
              color="primary"
              size="32"
            >
              {{ icons.mdiLockReset }}
            </v-icon>
            {{ $t('ChangePassword') }}
          </h5>
          <v-alert
            text
            color="primary"
          >
            <small
              v-for="(item, index) in $t('PasswordGuide')"
              :key="index"
              class="d-block mb-1"
            >
              <div class="d-flex">

                <li>

                </li>
                <span>
                  {{ item }}
                </span>
              </div>
            </small>
          </v-alert>
        </v-card-text>

        <v-card-text class="pb-0">
          <v-form
            ref="passwordForm"
            @submit.prevent="changePassword"
          >
            <v-row class="mb-0">
              <!-- password -->
              <v-col cols="12">
                <v-text-field
                  v-model="newPassword"
                  :rules="[validators.required,validators.passwordValidator]"
                  :label="$t('NewPassword')"
                  :type="isPasswordVisible ? 'text' : 'password'"
                >
                  <template #append>
                    <v-btn
                      icon
                      @click="isPasswordVisible = !isPasswordVisible"
                    >
                      <v-icon v-if="isPasswordVisible">
                        {{ icons.mdiEyeOffOutline }}
                      </v-icon>
                      <v-icon v-if="!isPasswordVisible">
                        {{ icons.mdiEyeOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>

              <!-- Confirm Password -->
              <v-col cols="12">
                <v-text-field
                  v-model="confirmPassword"
                  :label="$t('ConfirmPassword')"
                  :rules="[validators.required,validators.checkConfirmPassword(newPassword, confirmPassword), validators.passwordValidator]"
                  :type="isConfirmPasswordVisible ? 'text' : 'password'"
                >
                  <template #append>
                    <v-btn
                      icon
                      @click="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                    >
                      <v-icon v-if="isConfirmPasswordVisible">
                        {{ icons.mdiEyeOffOutline }}
                      </v-icon>
                      <v-icon v-if="!isConfirmPasswordVisible">
                        {{ icons.mdiEyeOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>

              <!-- reset password -->
              <v-col cols="12">
                <v-btn
                  block
                  color="primary"
                  type="submit"
                >
                  {{ $t('ChangePassword') }}
                </v-btn>
              </v-col>
            </v-row>
            <!-- back to login -->
            <v-card-actions class="d-flex justify-center align-center">
              <router-link
                :to="{name:'auth-login'}"
                class="d-flex align-center text-sm"
              >
                <span>{{ $t('BackToLogin') }}</span>
                <v-icon
                  size="24"
                  color="primary"
                >
                  {{ icons.mdiChevronLeft }}
                </v-icon>
              </router-link>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { i18n } from '@/plugins/i18n/index'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import { useRouter } from '@core/utils'
import { checkConfirmPassword, passwordValidator, required } from '@core/utils/validation'
// eslint-disable-next-line object-curly-newline
import { mdiChevronLeft, mdiEyeOffOutline, mdiEyeOutline, mdiLockReset } from '@mdi/js'
import themeConfig from '@themeConfig'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

export default {
  components: { AppBarI18n },

  setup() {
    const { route } = useRouter()
    const newPassword = ref()
    const confirmPassword = ref()
    const passwordForm = ref(null)
    const isPasswordVisible = ref(false)
    const isConfirmPasswordVisible = ref(false)
    const loading = ref(false)
    const toast = useToast()
    const showToast = () => toast.error(i18n.t('errorManageEvents'))
    const successToast = () => toast.success(i18n.t('PasswordChangeSuccessful'))

    const changePassword = () => {
      const isFormValid = passwordForm.value.validate()

      if (!isFormValid) return

      loading.value = true
      if (newPassword.value === confirmPassword.value) {
        store
          .dispatch('resetPassword', { token: route.value.params.token, password: newPassword.value })
          .then(() => {
            successToast()
            router.push({ name: 'auth-login' })
          })
          .catch(() => showToast())
          .finally(() => {
            loading.value = false
          })
      }
    }

    return {
      changePassword,
      newPassword,
      confirmPassword,
      isPasswordVisible,
      isConfirmPasswordVisible,
      loading,
      passwordForm,

      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
        mdiLockReset,
      },
      validators: {
        checkConfirmPassword,
        passwordValidator,
        required,
      },
    }
  },
}
</script>
